import React from "react"
import { Box, Container, Grid, Typography, Button, makeStyles } from "@material-ui/core"
import '@fontsource/metropolis'
import TopLayout from "../gatsby-theme-material-ui-top-layout/components/top-layout"
import theme from '../gatsby-theme-material-ui-top-layout/theme'
import BookkeepingSVG from '../assets/bookkeeping.svg'
import PayrollSVG from '../assets/payroll.svg'
import TaxSVG from '../assets/tax.svg'
import SevvenLogoText from '../assets/SevvenLogoText.svg'
import scrollTo from "gatsby-plugin-smoothscroll"
import { Helmet } from 'react-helmet'

const blue = '#004175'
const altBlue = '#2F86A3'

const useStyles = makeStyles({
  callToActionButton: {
    textTransform: 'none',
    background: 'transparent',
    // background: 'linear-gradient(90deg, rgba(73,172,188,1) 0%, rgba(0,65,117,1) 100%)',
    fontWeight: 600,
    borderWidth: 1,
    borderColor: blue,
    borderStyle: 'solid',
    borderRadius: 6,
    color: blue,
    "&:hover, &:focus": {
      background: blue,
      color: 'white'
    }
  },
  pricingTableContainer: {
    boxShadow: '0 0 20px #0000001f'
  }
})

const CallToActionButton = (text, link = "") => {
  const classes = useStyles()
  return (
    <Button href={link} className={classes.callToActionButton}>
      <Box my={0.5} mx={1.7}>
        <Typography variant="h6" color='white'>{text}</Typography>
      </Box>
    </Button>
  )
}

const NavBar = () => (
  <Container>
    <Box height={60} display="flex" alignItems="center">
      <SevvenLogoText height={30} />
    </Box>
  </Container>

)

const HeroSection = () => {
  const classes = useStyles()
  return (
    <Container height={400}>
      <Box display="flex" justifyContent="center" py={14} mb={6} flexDirection="column">
        <Box fontWeight={500}>
          <Typography variant="h3" component="h1" align="center"><Box fontWeight={600} color={blue}>Simple accounting services for your business.</Box></Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="h5" component="p" align="center">Bookkeeping, payroll, and tax services tailored just for you.</Typography>
        </Box>
        <Box display="flex" justifyContent="center" mt={10}>
          <Button onClick={() => scrollTo('#pricing')} className={classes.callToActionButton}>
            <Box my={0.5} mx={1.7}>
              <Typography variant="h6" color='white'>Get Started</Typography>
            </Box>
          </Button>
        </Box>
      </Box>
    </Container>
  )
}

const InfoSection = () => (
  <Container>
    <Box mb={8}>
      <Typography variant="h5" component="h3" align="center" fontWeight={700}>
        <Box fontWeight={600} color={blue}>
          A dedicated team of experts at your side
          </Box>
      </Typography>
      <Box mt={3}>
        <Grid container justify="center">
          <Grid item xs={12} sm={6}>
            <Typography component="p" align="center">We are a human driven organization utilizing technology, not the other way around. A dedicated accountant works on each business with our in-house support technology and team to ensure your success.</Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>


    <Box my={2}>
      <Grid container justifyContent="center" alignItems="center" wrap="wrap-reverse">
        <Grid item xs={12} sm={6}>
          <Box fontWeight={600} mb={3}>
            <Typography variant="h5" component="h3" align="center">
              <Box fontWeight={600} color={blue}>
                Bookkeeping made easy
              </Box>
            </Typography>
          </Box>
          <Box>
            <Typography component="p" align="center">Eliminate inaccurate books and focus more on your business. Our full-service, fully-staffed operations cover all business sizes. We provide you with an end-of-year package, or bundle bookkeeping with our tax services. We provide tax preparation, filing, and tax advisory support.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center" justifyContent="center" my={8} px={4}>
            <BookkeepingSVG height={200} />
          </Box>
        </Grid>
      </Grid>
    </Box>

    <Box my={2}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center" justifyContent="center" my={8} px={4}>
            <PayrollSVG height={200} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box fontWeight={600} mb={3}>
            <Typography variant="h5" component="h3" align="center">
              <Box fontWeight={600} color={blue}>
                Payroll services
              </Box>
            </Typography>
          </Box>
          <Box>
            <Typography component="p" align="center">Stay in compliance and reduce friction with payroll setup and maintenance. We work with our trusted partners to automate payroll, maintain state and federal compliance, integrate with bookkeeping, and more. We'll work closely with you to ensure all your needs are met.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>

    <Box my={2}>
      <Grid container justifyContent="center" alignItems="center" wrap="wrap-reverse">
        <Grid item xs={12} sm={6}>
          <Box fontWeight={600} mb={3}>
            <Typography variant="h5" component="h3" align="center">
              <Box fontWeight={600} color={blue}>
                Tax advisory and preparation
              </Box>
            </Typography>
          </Box>
          <Box>
            <Typography component="p" align="center">Tax planning can help avoid tax risks and other surprises. Our team of experts can help with tax-related financial decisions and risk mitigation. Our team also offers a full tax preparation that can be optionally bundled with our bookkeeping and payroll services.

            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center" justifyContent="center" my={8} px={4}>
            <TaxSVG height={200} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  </Container>
)

const PricingTableItem = (pricingItem) => {
  const classes = useStyles()

  return (
    <Grid item xs={12} sm={6} md={4} lg>
      <Box px={2} py={3} m={2} boxShadow={10} borderRadius={4} bgcolor="white" className={classes.pricingTableContainer}>
        <Box align="center" mb={2}>
          <Typography variant="h6" component="div"><Box fontWeight={700} color={blue}>{pricingItem.title}</Box></Typography>
          <Typography variant="h4" component="div"><Box fontWeight={700} my={4} color={altBlue}>${pricingItem.annualPrice}/m</Box></Typography>
          <Typography>If billed anually</Typography>
          <Typography component="div"><Box color='#808080'>${pricingItem.monthlyPrice}/m if billed monthly</Box></Typography>
          <Box my={6}>
            {CallToActionButton('Get Started', pricingItem.link)}
          </Box>
          <Typography>{pricingItem.expenses}</Typography>
        </Box>
      </Box>
    </Grid>
  )
}
const PricingTableSection = () => (
  <Container id="pricing">
    <Box mb={5} mt={12}>
      <Typography variant="h4" component="h2" align="center">
        <Box py={10} component="span" fontWeight={600} color={blue}>Pricing</Box>
      </Typography>
    </Box>

    <Box mb={5}>
      <Grid container justify="center" alignItems="center">
        {[
          { title: 'Bronze', annualPrice: 145, monthlyPrice: 195, expenses: 'Monthly expenses less than $3k', link: 'https://buy.stripe.com/4gw3cMeZR5rPgSY289' },
          { title: 'Silver', annualPrice: 295, monthlyPrice: 345, expenses: 'Monthly expenses less than $25k', link: 'https://buy.stripe.com/bIYdRq2d5aM9auA002' },
          { title: 'Gold', annualPrice: 445, monthlyPrice: 495, expenses: 'Monthly expenses less than $50k', link: 'https://buy.stripe.com/4gw6oY7xp4nLcCI147' },
          { title: 'Platinum', annualPrice: 745, monthlyPrice: 795, expenses: 'Monthly expenses less than $75k', link: 'https://buy.stripe.com/5kAeVudVN9I5gSY3ch' },
          { title: 'Diamond', annualPrice: 945, monthlyPrice: 995, expenses: 'Monthly expenses greater than $75k', link: 'https://buy.stripe.com/dR6bJicRJ8E11Y49AG' }
        ].map((pricingItem) => PricingTableItem(pricingItem))}
      </Grid>
    </Box>
  </Container>
)

const Home = () => {
  return (
    <TopLayout theme={theme}>
      <Box className="application">
        <Helmet>
          <title>Sevven: Simple Accounting Services</title>
          <meta name="description" content="Bookkeeping, payroll, and tax services tailored just for you" />
          <link rel="icon" type="image/png" href="favicon.ico" />
        </Helmet>
        <NavBar />
        <HeroSection />
        <InfoSection />
        <PricingTableSection />
        <Box align="center" height={40} mt={10}>
          © 2021 Sevven.co  All rights reserved.
        </Box>
      </Box>
    </TopLayout>
  )
}

export default Home
